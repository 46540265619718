/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import { classNames } from "../helpers/classNames";
import { currencyFormatterOptions } from "../helpers/constants";
import sendMail, { sendMailWithAttachment } from "../helpers/emailSender";
import {
  useGetCountryList,
  useGetFundRaisedByChildCampaign,
} from "../helpers/hooks";
import paymentMethodConfigGenerator from "../helpers/paymentMethodConfigGenerator";
import {
  hideSpinner,
  showToast,
  showSpinner,
  showModal,
} from "../store/actions";
import { StateContext } from "../store/context";
import infoIcon from "../assets/info-icn.svg";
import CurrencyDropdown from "./currencyDropdown";
import Country from "./country";
import styles from "./donateNowForm.module.scss";
import PaymentSuccessModal from "./paymentSuccessModal";
import MobileNumberInput from "./mobileNumberInput";
export const DonateNowForm = ({
  campaign,
  childCampaign,
  parentFundRaised,
}) => {
  const { state, dispatch } = useContext(StateContext);
  const currency = state.currency;
  const amount = state.amount;
  const childId = childCampaign ? childCampaign.contentful_id : "";
  const [taxExemption, setTaxExemption] = useState("");
  const [idProofLabel, setIdProofLabel] = useState("");
  const [idProof, setIdProof] = useState(false);
  const [showNationalityPopup, setShowNationalityPopup] = useState(false);
  const [showPancardPopup, setShowPancardPopup] = useState(false);
  const { data: countryList = [] } = useGetCountryList();
  const isInternationalModeSupported =
    campaign.ngo.intRzpKeyId && campaign.ngo.intRzpKeyEncryptedSecret;
  const isIndianCitizen =
    taxExemption === "indian-citizen-with-tax-benefits-in-india" ||
    taxExemption === "non-resident-indian-citizen-with-tax-benefits-in-india";
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    currency,
    ...currencyFormatterOptions,
  }).format(amount);

  const { data: { fundRaised } = {} } = useGetFundRaisedByChildCampaign(
    campaign.slug,
    childId
  );

  useEffect(() => {
    if (taxExemption) {
      // indian citizen trying to pay in foreign currency
      if (isIndianCitizen && currency !== "INR") {
        showToast(dispatch, "Indian citizen should donate in INR", "danger");
        dispatch({
          type: "CURRENCY_CHANGED",
          payload: "INR",
        });
      }
      // non indian citizen trying to pay in INR
      else if (
        !isIndianCitizen &&
        currency === "INR" &&
        isInternationalModeSupported
      ) {
        showToast(
          dispatch,
          "Non Indian citizen should not donate in INR",
          "danger"
        );
        dispatch({
          type: "CURRENCY_CHANGED",
          payload: "USD",
        });
      }
      // non indian citizen trying to pay to a campaign that does not support international payments
      else if (!isInternationalModeSupported && currency !== "INR") {
        showToast(
          dispatch,
          "This campaign does not accept international payments",
          "danger"
        );
      }
    }
  }, [taxExemption]);

  useEffect(() => {
    if (taxExemption) {
      dispatch({
        type: "AMOUNT_CHANGED",
        payload: currency === "INR" ? 10000 : 250,
      });
      // indian citizen trying to pay in foreign currency
      if (currency !== "INR" && isIndianCitizen) {
        showToast(dispatch, "Indian citizen should donate in INR", "danger");
        setTaxExemption("others");
      }
      // non indian citizen trying to pay in INR
      else if (
        !isIndianCitizen &&
        currency === "INR" &&
        isInternationalModeSupported
      ) {
        showToast(
          dispatch,
          "Non Indian citizen should not donate in INR",
          "danger"
        );
        setTaxExemption(
          "non-resident-indian-citizen-with-tax-benefits-in-india"
        );
      }
    }
  }, [currency]);

  const handleIdProofType = e => {
    const val = e.target.value;

    if (val !== "") {
      setIdProof(true);
      setIdProofLabel("Enter " + e.target.value);
    } else {
      setIdProof(false);
    }
  };

  const handleChange = e => {
    let customAmount = null;
    customAmount = document.getElementsByClassName("customAmount")[0];
    if (
      customAmount.value.length > 1 &&
      customAmount.value.search(/^0+(?!$)/g) > -1
    ) {
      customAmount.value = customAmount.value.replace(/^0+(?!$)/g, "");
    }
    dispatch({
      type: "AMOUNT_CHANGED",
      payload: +e.target.value,
    });
  };

  const checkValidation = async e => {
    e.persist();
    e.preventDefault();

    handleSubmit(e);
  };

  const handleShowNationalityPopup = e => {
    setShowNationalityPopup(!showNationalityPopup);
  };

  const handleShowPancardPopup = e => {
    setShowPancardPopup(!showPancardPopup);
  };

  const handleSubmit = async e => {
    e.persist();
    e.preventDefault();

    const crypto = require("crypto");

    let json = {};
    if (childCampaign && amount + fundRaised > childCampaign.goal) {
      showToast(
        dispatch,
        "Total amount should be less than the goal",
        "danger"
      );
    } else if (!childCampaign && amount + parentFundRaised > campaign.goal) {
      showToast(
        dispatch,
        "Total amount should be less than the goal amount",
        "danger"
      );
    } else {
      new FormData(e.target).forEach((value, key) => {
        if (key === "customAmount") {
          json.amount = value * 100;
        } else {
          json[key] = value;
        }
      });

      json.appName = "campaignAggregator";

      let raw = JSON.stringify(json);
      showSpinner(dispatch, "Opening payment gateway...");
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.set("amount", `${currency}${amount}`);
      const finalQuery = urlSearchParams.toString();
      window.history.pushState({}, "", `?${finalQuery}`);
      try {
        let requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: raw,
          redirect: "follow",
        };

        const resdata = await fetch(
          `${process.env.GATSBY_API_BASE_URL}/campaignAggregator/createRazopayOrder`,
          requestOptions
        ).then(resp => resp.json());

        hideSpinner(dispatch);
        let paymentId = "";
        let orderId = "";
        let signature = "";

        const options = {
          // key: json.rzpKeyId,
          anonymous: resdata.orderDetails.anonymous,
          currency: resdata.orderDetails.currency,
          amount: resdata.orderDetails.amount,
          [`order_id`]: resdata.orderDetails.id,
          name: "Metta Social Fundraisers",
          description: "Support",
          // account_id: campaign.ngo.accountId,
          image: `${process.env.GATSBY_PUBLIC_URL}/icons/logo.png`,
          config: paymentMethodConfigGenerator(json.taxExemption),

          handler: async function (response) {
            paymentId = response.razorpay_payment_id;
            orderId = resdata.orderDetails.id;
            signature = response.razorpay_signature;

            showSpinner(dispatch, "Verifying your payment...");

            if (signature) {
              const secret = resdata.secret;
              // const secret = "HFTzj0TiGm2y2nQdERvvLa5n";
              const hmac = crypto.createHmac("sha256", secret);
              hmac.update(orderId + "|" + paymentId);
              let generatedSignature = hmac.digest("hex");

              let isSignatureValid = generatedSignature === signature;
              if (isSignatureValid === true) {
                console.error("verified");
              }
            }

            sendMailWithAttachment({
              templateName: "donationSuccess",
              sendTo: resdata.orderDetails.notes.email,
              subject: `Thank you for your contribution for ${campaign.title} campaign`,
              moduleName: "campaign-aggregator",
              templateData: {
                name: resdata.orderDetails.notes.name,
              },
              attachmentData: [
                {
                  template: "certificate",
                  name: resdata.orderDetails.notes.name,
                  campaignName: campaign.title,
                  ngoName: campaign.ngo.ngoName
                    .toLowerCase()
                    .replace(/\s+/g, " ")
                    .replace(/\s/g, "-"),
                },
              ],
              isHtml: true,
            });

            hideSpinner(dispatch);
            showModal(dispatch, () => <PaymentSuccessModal />);
          },
          modal: {
            ondismiss: function () {
              sendMail({
                templateName: "followup",
                sendTo: resdata.orderDetails.notes.email,
                subject: `Complete the transaction for the ${campaign.title} campaign`,
                moduleName: "campaign-aggregator",
                templateData: {
                  campaignName: campaign.title,
                  link: `${process.env.GATSBY_PUBLIC_URL}/campaign/${campaign.slug}`,
                  linkText: "Complete Payment",
                },
              });
            },
          },
          prefill: {
            name: resdata.orderDetails.notes.firstName,
            email: resdata.orderDetails.notes.email,
            contact: resdata.orderDetails.notes.mobileNo,
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        console.error(err, JSON.stringify(err));
        showToast(dispatch, err.message, "danger");
        hideSpinner(dispatch);
      }
    }
  };
  return (
    <form
      className={`mx-0 form row justify-content-center ${styles.donateNowForm}`}
      onSubmit={checkValidation}
    >
      <>
        {campaign.ngo.rzpKeyId.includes("partner") && (
          <input
            type="hidden"
            name="accountId"
            value={campaign.ngo.accountId}
          />
        )}
        <input
          type="hidden"
          name="appName"
          value="campaignAggregator"
          required
        />
        <input type="hidden" name="env" value={process.env.GATSBY_ENV} />
        <input type="hidden" name="campaignId" value={campaign.slug} />
        {childCampaign && (
          <input
            type="hidden"
            name="childCampaignId"
            value={childCampaign.contentful_id}
            required
          />
        )}

        <input
          type="hidden"
          name="RZP_KEY_ID"
          value={
            currency === "INR"
              ? campaign.ngo.rzpKeyId
              : campaign.ngo.intRzpKeyId
          }
        />

        <input
          type="hidden"
          name="RZP_KEY_SECRET"
          value={
            currency === "INR"
              ? campaign.ngo.rzpKeyEncryptedSecret.rzpKeyEncryptedSecret
              : campaign.ngo.intRzpKeyEncryptedSecret.intRzpKeyEncryptedSecret
          }
        />
      </>
      <header className="modal-header border-0 row mt-0">
        <p className="modal-title text-uppercase text-white bg-secondary px-2 py-1 rounded mb-3">
          <small>Your support is appreciated</small>
        </p>
        <h5>You are contributing {formattedAmount} towards a noble cause</h5>
      </header>
      <div>
        <div className="row">
          <div className="form-group formGroup mx-0 col-12 col-md-6 mb-4 mb-md-4 pr-2 pr-md-3">
            {isInternationalModeSupported && <CurrencyDropdown />}
            <input
              className={classNames({
                [`form-control customAmount ${styles.customAmount}`]: true,
                "pl-3": !isInternationalModeSupported,
              })}
              type="number"
              name="customAmount"
              value={amount}
              maxLength="9"
              min={1}
              onChange={handleChange}
              required
            ></input>
            <label htmlFor="customAmount">You Are Donating</label>
          </div>
        </div>
        <div className={`${styles.sectionTitle} mt-0`}>
          <span>Basic Details</span>
          <hr />
        </div>
        <div className="row">
          <div className="form-group formGroup mx-0 col-6 col-md-6 mb-4 mb-md-4 pr-2 pr-md-3">
            <input
              className="form-control"
              type="text"
              id="firstName"
              name="firstName"
              required
              pattern="^[a-zA-Z ]*$"
              title="Only alphabets are allowed"
            ></input>
            <label htmlFor="firstName">First Name</label>
            <div className="invalid-feedback">
              Please enter a valid first name.
            </div>
          </div>
          <div className="form-group formGroup mx-0 col-6 col-md-6 mb-4 mb-md-4 pl-2 pl-md-3">
            <input
              className="form-control"
              type="text"
              id="lastName"
              name="lastName"
              required
              pattern="^[a-zA-Z ]*$"
              title="Only alphabets are allowed"
            ></input>
            <label htmlFor="lastName">Last Name</label>
            <div className="invalid-feedback">
              Please enter a valid last name.
            </div>
          </div>
        </div>
        <div className="form-group formGroup mx-0 mb-4 px-0 row col-12 align-items-center">
          <input
            type="checkbox"
            className={`col-auto mr-2 pr-0 ${styles.checkBox}`}
            id="anonymous"
            name="anonymous"
            value="true"
          />
          <label
            htmlFor="anonymous"
            className={`mb-0 pl-0 col-auto ${styles.anonymous} font-weight-bold`}
          >
            I want to donate as anonymous
          </label>
        </div>
        <div className="row">
          <div className="form-group formGroup mx-0 row col-12 col-md-6 mb-4 mb-md-4">
            <input
              className="form-control "
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              required
            ></input>
            <label htmlFor="email">Email Address</label>
            <div className="invalid-feedback">
              Please enter a valid email address.
            </div>
          </div>

          <div className="form-group formGroup standard-field mx-0 row col-12 col-md-6 mb-4 mb-md-4">
            <MobileNumberInput name="mobileNo" onChangeFunc={false} />
            <label htmlFor="mobileNo">
              Mobile Number<span className="ml-1">*</span>
            </label>
            <div className="invalid-feedback">
              Please enter a valid mobile number.
            </div>
          </div>
        </div>
        <div className={styles.sectionTitle}>
          <span>Address Details</span>
          <hr />
        </div>

        <div className={`row`}>
          <div className="form-group formGroup mb-4 mx-0 row col-12 col-md-6">
            {countryList && (
              <Country
                countryList={countryList}
                id="country"
                name="country"
                required
              />
            )}

            <label htmlFor="country">Country of Residence</label>
            <div className="invalid-feedback">Please select a country.</div>
          </div>

          <div className="form-group formGroup mx-0 row mb-4 col-12 col-md-6 pl-auto pl-md-3 position-relative">
            {countryList && (
              <Country
                countryList={countryList}
                id="nationality"
                name="nationality"
                required
              />
            )}
            <label htmlFor="nationality">
              <img
                src={infoIcon}
                className={`${styles.infoIcon} cursor-pointer`}
                alt="Info icon"
                onClick={handleShowNationalityPopup}
              />
              &nbsp;Nationality/Citizenship
            </label>
            <div
              className={`${styles.nationalityPopUp} bg033B5D p-3 ${
                !showNationalityPopup ? "d-none" : ""
              } `}
            >
              <span className="font-weight-bold font-14 text-white d-block mb-3">
                Your nationality/citizenship is determined by the country whose
                passport you hold.
              </span>
              <span className="font-14 text-white">
                This is very important from a regulatory compliance perspective
                for Social Purpose organizations. The donations from Indian &
                Foreign citizens need to be segregated into different bank
                accounts by law. So kindly enter the correct information.
              </span>
            </div>
            <div className="invalid-feedback">
              Please enter a valid nationality.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group formGroup col-12 position-relative mb-4">
            <input
              className="form-control"
              type="text"
              id="address"
              name="address"
              placeholder="Your Address"
              required
            ></input>
            <label htmlFor="address">Your Address</label>
            <div className="invalid-feedback">
              Please enter a valid address.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group formGroup mx-0 row col-6 col-md-6 mb-4 mb-md-2 pr-2 pr-md-3">
            <input
              className="form-control"
              type="text"
              id="postalCode"
              name="postalCode"
              placeholder="Postal Code"
              pattern="\d*"
              title="Only numbers are allowed"
              required
            ></input>
            <label htmlFor="postalCode">Postal Code</label>
            <div className="invalid-feedback">
              Please enter a valid postal code.
            </div>
          </div>
          <div className="form-group formGroup mx-0 row col-6 col-md-6 mb-4 mb-md-2 pl-2 pl-md-3">
            <input
              className="form-control"
              type="text"
              id="state"
              name="state"
              placeholder="State/Province"
              pattern="^[a-zA-Z ]*$"
              title="Only alphabets are allowed"
              required
            ></input>
            <label htmlFor="state">State/Province</label>
            <div className="invalid-feedback">
              Please enter a valid state/province.
            </div>
          </div>
        </div>
        <div className={styles.sectionTitle}>
          <span>Other Details</span>
          <hr />
        </div>

        <div className="row ">
          <div className="form-group formGroup mx-0 mb-4 mb-md-4 row col-12 col-md-12">
            <select
              className={`form-control w-100 mb-0 mb-md-0 font-18 ${styles.selectionFormField}`}
              name="taxExemption"
              id="taxExemption"
              value={taxExemption}
              onChange={e => {
                setTaxExemption(e.target.value);
              }}
              required
            >
              <option value="" selected disabled className={styles.firstoption}>
                Select Tax Exemption Status
              </option>
              <option value="indian-citizen-with-tax-benefits-in-india">
                Indian Citizen with tax benefits in India
              </option>

              <option value="non-resident-indian-citizen-with-tax-benefits-in-india">
                Non-Resident Indian Citizen with tax benefits in India
              </option>

              {isInternationalModeSupported && (
                <option value="us-citizen">US Citizen</option>
              )}
              {isInternationalModeSupported && (
                <option value="others">Others</option>
              )}
            </select>
            <label htmlFor="taxExemption">Tax Exemption Status</label>
            <div className="invalid-feedback">
              Please select a tax exemption status.
            </div>
          </div>
        </div>

        <div className="mx-1 row">
          <div className="form-group formGroup mx-0 mb-4 px-0 pr-md-3 pr-2 row col-12 align-items-center">
            <input
              type="checkbox"
              className={`mr-2 ${styles.checkBox}`}
              id="_80"
              name="_80"
              value={true}
            />
            <label
              htmlFor="_80"
              className={`mb-0 ${styles.anonymous} font-weight-bold`}
            >
              I need 80G tax certificate
            </label>
          </div>
        </div>

        <div className="mb-4 row">
          <div className="form-group formGroup mx-0 col-12 col-md-12 mb-4 px-2 px-md-3">
            <select
              className={`form-control w-100 mb-0 mb-md-0 font-18 ${styles.selectionFormField}`}
              name="idProofType"
              id="idProofType"
              onChange={e => {
                handleIdProofType(e);
              }}
              required
            >
              <option value="" selected disabled className={styles.firstoption}>
                Preferred ID Proof
              </option>
              <option value="PAN Card">PAN Card Number</option>
              <option value="Aadhar Card ">Aadhar Card Number</option>
              <option value="Valid Passport">Valid Passport Number</option>
              <option value="Electors Photo Identity Card">
                Electors Photo Identity Card Number
              </option>
              <option value="Driving License">Driving License Number</option>
              <option value="Ration Card">Ration Card Number</option>
              <option value="Taxpayer Identification Number">
                Taxpayer Identification Number
              </option>
            </select>
            <label htmlFor="idProofType">Preferred ID Proof</label>
          </div>
        </div>
        {idProof && (
          <div className="mb-4 row">
            <div className="form-group formGroup mx-0 col-12 col-md-12 mb-4 px-2 px-md-3">
              <input
                className="form-control"
                type="text"
                id="idProof"
                name="idProof"
                placeholder={idProofLabel}
                required
              ></input>
              <label htmlFor="panCard">
                {idProofLabel === "Enter PAN Card" ? (
                  <img
                    src={infoIcon}
                    className={`${styles.infoIcon} cursor-pointer`}
                    alt="Info icon"
                    onClick={handleShowPancardPopup}
                  />
                ) : (
                  ""
                )}
                {" " + idProofLabel}
              </label>
              <div
                className={`${styles.panCardPopUp} bg033B5D p-3 ${
                  !showPancardPopup ? "d-none" : ""
                } `}
              >
                <span className="font-14 text-white">
                  W.E.F 1st June 2020, All charitable trusts are required to
                  submit donor details including PAN in order for 80g tax
                  excemption to be claimed by the donor while claiming IT
                  assessment returns
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <button
        type="submit"
        className="py-2 btn d-inline-flex align-items-center justify-content-center col-12 button-8BC540"
        id="popup-donate-now"
      >
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-suit-heart-fill"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
        </svg>
        <span
          className={`ml-2 h5 mb-0 pe-none font-16 text-uppercase ${styles.btnTextPadding}`}
        >
          Donate {formattedAmount}
        </span>
      </button>
      <span className={`${styles.tnc} d-block`}>
        By proceeding, you are agreeing to our&nbsp;
        <a
          href="https://www.mettasocial.com/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          terms and conditions.
        </a>
      </span>
    </form>
  );
};
