import React from "react";
import { useGetCampaignDonationData } from "../helpers/hooks";

function ParentCampaignDonorCount(props) {
  const slug = props.slug;
  let generousData = useGetCampaignDonationData("amount", slug);

  return (
    <small className="px-4 bg-secondary py-1 rounded d-inline-block text-white text-uppercase text-center">
      {(generousData &&
        generousData.campaignPayments &&
        generousData.campaignPayments.length) ||
        "0"}{" "}
      Donors
    </small>
  );
}
export default ParentCampaignDonorCount;
