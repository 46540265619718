import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useState } from "react";
import styles from "./faq.module.scss";

const FAQSection = props => {
  let indexPlus;

  const [active, setActive] = useState(0);

  const eventHandler = (e, index, active) => {
    e.preventDefault();

    if (active === index) {
      setActive(false);
    } else {
      setActive(index);
    }
  };

  const indexCount = index => {
    indexPlus = index + 1;
    return indexPlus;
  };

  return (
    <section className={`col-12 ${styles.faqSection}`}>
      <span className="pill-label pill-bg-gradient">ask us anything</span>
      <h3 className="section-title text-black">Frequently Asked Questions</h3>
      <div className={styles.faqContainer}>
        {props.faqcontent
          .sort((a, b) => (a.sequenceno > b.sequenceno ? 1 : -1))
          .map((x, index) => {
            return (
              <div className={styles.faqRow} key={index}>
                <div
                  className={`d-flex justify-content-between positive-relative align-items-center cursor-pointer`}
                >
                  <div
                    onClick={e => eventHandler(e, index, active)}
                    className={`${
                      styles.question
                    } w-100 font-weight-bolder font-20 position-relative ${
                      active === index ? "active" : "inactive"
                    } `}
                    aria-expanded={active === index ? "true" : "false"}
                    aria-controls={"sect-" + indexCount(index)}
                    aria-disabled={active === index ? "false" : "true"}
                    tabIndex={indexCount(index)}
                  >
                    Q{index + 1}.&nbsp;
                    {x.question}
                    <span
                      className={active === index ? styles.plus : styles.minus}
                    ></span>
                  </div>
                </div>

                <div
                  id={"sect-" + indexCount(index)}
                  className={`${
                    active === index ? styles.panelopen : styles.panelclose
                  } w-100`}
                >
                  <div className={styles.answerContainer}>
                    <div className={`${styles.answer} mb-0`}>
                      {documentToReactComponents(JSON.parse(x.answer.raw))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default FAQSection;
