import React from "react";
import { useGetPaymentsByChildCampaignId } from "../helpers/hooks";
function ChildCampaignDonorCount(props) {
  const slug = props.slug;

  const childCampaignId = props.childCampaign.contentful_id;
  let generousData = useGetPaymentsByChildCampaignId(
    "amount",
    slug,
    childCampaignId
  );
  return (
    <small className="px-4 bg-secondary py-1 rounded d-inline-block text-white text-uppercase text-center">
      {(generousData &&
        generousData.campaignPayments &&
        generousData.campaignPayments.length) ||
        "0"}{" "}
      Donors
    </small>
  );
}
export default ChildCampaignDonorCount;
