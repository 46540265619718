import React from "react";
import { currencyFormatterOptions } from "../helpers/constants";
import nameInitials from "../helpers/nameInitials";
import styles from "./donorDetails.module.scss";

export default function DonorDetails({ donor }) {
  return (
    <div className={`d-flex justify-content-between ${styles.donorRow}`}>
      <div className={`text-white d-flex`}>
        <span className={`${styles.initials} text-uppercase text-center`}>
          {nameInitials(donor.name)}
        </span>
        <span className="text-white">
          <p className={`mb-0 font-18 ${styles.donorName}`}>{donor.name}</p>
          <p className={`mb-0 font-14`}>{donor.country}</p>
        </span>
      </div>
      <span className={`text-54EDFF font-18`}>
        {donor.currency
          ? new Intl.NumberFormat("en-IN", {
              currency: donor.actualCurrency,
              ...currencyFormatterOptions,
            }).format(donor.actualAmount)
          : null}
      </span>
    </div>
  );
}
