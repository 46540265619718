import React, { useContext } from "react";
import {
  currencyFormatterOptions,
  domesticPaymentOptions,
  internationalPaymentOptions,
} from "../helpers/constants";
import { StateContext } from "../store/context";
import styles from "./paymentOptions.module.scss";
export default function PaymentOptions() {
  const { state, dispatch } = useContext(StateContext);
  const { currency, amount } = state;
  const paymentOptions =
    currency === "INR" ? domesticPaymentOptions : internationalPaymentOptions;
  const timeStamp = Date.now();
  const onChange = e => {
    dispatch({
      type: "AMOUNT_CHANGED",
      payload: +e.target.value,
    });
  };
  return (
    <div className="row">
      {paymentOptions.reverse().map(paymentOption => {
        return (
          <div
            className={`text-center position-relative col-4`}
            key={`${paymentOption}`}
          >
            <input
              type="radio"
              name="amount"
              required
              className={styles.input}
              id={`donationOption-${paymentOption}-${timeStamp}`}
              value={paymentOption}
              onChange={onChange}
              checked={amount === paymentOption}
            />
            <label
              className={`mb-0 px-4 ${styles.label} ${styles.donation}`}
              htmlFor={`donationOption-${paymentOption}-${timeStamp}`}
            >
              {new Intl.NumberFormat("en-IN", {
                currency: currency,
                ...currencyFormatterOptions,
              }).format(paymentOption)}
            </label>
          </div>
        );
      })}
    </div>
  );
}
