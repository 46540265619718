import React, { useState, useRef, useEffect, useCallback } from "react";
import styles from "./country.module.scss";

const Country = props => {
  const defaultOptions = props.countryList;
  const [options, setOptions] = useState([]);
  const ulRef = useRef();
  const countryRef = useRef();
  const onInputChange = useCallback(event => {
    setOptions(
      defaultOptions.filter(option =>
        option.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  });
  useEffect(() => {
    countryRef.current.addEventListener("click", event => {
      event.stopPropagation();
      ulRef.current.style.display = "block";
      onInputChange(event);
    });
    document.addEventListener("click", event => {
      // ulRef.current.style.display = "none";
    });
  }, [onInputChange]);

  return (
    <div>
      <input
        id={props?.id}
        type="text"
        name={props?.name}
        className="form-control"
        placeholder="Search"
        ref={countryRef}
        onChange={onInputChange}
      />
      <ul
        id="results"
        className={`${styles.listGroup} bg-white position-absolute p-0 w-100`}
        ref={ulRef}
      >
        {options.map((option, index) => {
          return (
            <li
              className="p-2"
              key={index}
              onClick={e => {
                countryRef.current.value = option;
                ulRef.current.style.display = "none";
              }}
            >
              {option}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Country;
