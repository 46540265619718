import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useContext } from "react";
import { showModal } from "../store/actions";
import { StateContext } from "../store/context";
import YoutubeVideo from "./anthemvideo";
import FundUsageModal from "./fundUsageModal";
import styles from "./ourMission.module.scss";
import Picture from "./picture";

const OurMissionSection = ({ fundUsage, mission }) => {
  const { dispatch } = useContext(StateContext);

  const clickedPrevious = () => {
    const carousel = document.getElementById("mission-carousel");
    if (carousel) {
      const carouselItems = document.getElementsByClassName("carousel-item");
      const itemWidth = carouselItems[0].clientWidth;

      if (carousel.scrollLeft !== 0)
        carousel.scrollBy({ left: -itemWidth, top: 0, behavior: "smooth" });
      else
        carousel.scrollTo({
          left: carousel.scrollWidth,
          top: 0,
          behavior: "smooth",
        });
    }
  };
  const downloadFile = () => {
    window.open(mission.projectReport.file.url, "_blank");
  };
  const clickedNext = () => {
    const carousel = document.getElementById("mission-carousel");
    if (carousel) {
      const carouselItems = document.getElementsByClassName("carousel-item");
      const itemWidth = carouselItems[0].clientWidth;
      let calculatedItemWidth = 0;

      if (itemWidth * carouselItems.length !== carousel.scrollWidth) {
        calculatedItemWidth = carousel.scrollWidth / carouselItems.length;
      }

      const ItemWidth =
        calculatedItemWidth === 0 ? itemWidth : calculatedItemWidth;

      if (
        carousel.scrollLeft < carousel.scrollWidth - ItemWidth &&
        carousel.scrollLeft <
          carousel.scrollWidth - ItemWidth - carousel.scrollWidth * 0.05
      )
        carousel.scrollBy({ left: itemWidth, top: 0, behavior: "smooth" });
      else carousel.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    }
  };

  return (
    <section className="col-12 section-padding">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div
            className={`${styles.ourMissionContent} d-flex flex-column justify-content-center text-center text-lg-left`}
          >
            <div
              className={`font-44 font-weight-bold mb-0 line-height-1_3 ${styles.ourMissionContentTitle}`}
            >
              {mission.title}
            </div>
            <div
              className={`${styles.ourMissionContentSubtitle} font-20 text-637285`}
            >
              {documentToReactComponents(JSON.parse(mission.description.raw))}
            </div>
            <div>
              <button
                type="button"
                className={`${styles.captionButton} btn button-8BC540 col-7 col-md-4 col-lg-5 mb-4 mb-md-0 btn-custom font-16 fw-500 letter-spacing-019 text-uppercase`}
                onClick={() =>
                  showModal(dispatch, () => (
                    <FundUsageModal fundUsage={fundUsage} />
                  ))
                }
                id="fundsUtilization"
              >
                Funds Utilization
              </button>
              {mission.projectReport && (
                <button
                  type="button"
                  className={`${styles.reportButton} btn col-9 col-md-5 col-lg-6 text-white ml-0 ml-md-4 btn-custom font-16 fw-500 letter-spacing-019 text-uppercase`}
                  onClick={downloadFile}
                  id="downloadProjectReport"
                >
                  Download Project Report
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-end">
          <div
            className={`${styles.videoContainer} position-relative text-center`}
          >
            <div
              className={`${styles.backgroundBox} abs-bottom-left d-none d-lg-block`}
            ></div>
            <div
              id="mission-carousel"
              className={`${styles.carousel} d-flex w-100`}
            >
              {mission.missionYoutubeLinks &&
                mission.missionYoutubeLinks
                  .sort((a, b) => (a.sequenceno > b.sequenceno ? 1 : -1))
                  .map(x => {
                    return (
                      !x.match("^\\s+$") && (
                        <div
                          className={`carousel-item position-relative ${styles.ourMissionContainer}`}
                          key={x}
                        >
                          <YoutubeVideo video={x} classname={`abs-full`} />
                        </div>
                      )
                    );
                  })}
              {mission.missionImages &&
                mission.missionImages
                  .sort((a, b) => (a.sequenceno > b.sequenceno ? 1 : -1))
                  .map(x => {
                    return (
                      <div
                        className={`carousel-item ${styles.carouselItem} w-100`}
                        key={x.title}
                      >
                        <div className="w-100 h-100 loading-bg">
                          <Picture
                            image={x}
                            className={`${styles.carouselBGImage} abs-top-right img-default w-100 h-100`}
                          />
                          <Picture
                            image={x}
                            className={`abs-full imgInPictureContain`}
                          />
                        </div>
                      </div>
                    );
                  })}
            </div>
            {((mission.missionYoutubeLinks &&
              mission.missionYoutubeLinks.length > 1) ||
              (mission.missionImages && mission.missionImages.length > 1) ||
              (mission.missionYoutubeLinks && mission.missionImages)) && (
              <div
                className={`display-flex text-center mt-3 ${styles.carouselButtonContainer}`}
              >
                <img
                  className={`${styles.prev} mr-4 cursor-pointer`}
                  src="/icons/left.svg"
                  alt="Previous testimonial"
                  onClick={clickedPrevious}
                />
                <img
                  className={`${styles.next} cursor-pointer`}
                  src="/icons/right.svg"
                  alt="Next testimonial"
                  onClick={clickedNext}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMissionSection;
