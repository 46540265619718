const domesticPaymentInstruments = [
  {
    method: "upi",
    apps: [
      "phonepe",
      "google_pay",
      "bhim",
      "paytm",
      "imobile",
      "sbi",
      "amazon",
      "airtel",
    ],
    flows: ["collect", "qr", "intent"],
  },

  {
    method: "wallet",
  },
  {
    method: "netbanking",
  },
];

export default function paymentMethodConfigGenerator(preferredTaxExemption) {
  return {
    display: {
      blocks: {
        upi: {
          name: "Choose your preferred payment method",
          instruments: [
            {
              method: "card",
              types: ["debit", "credit"],
            },
          ].concat(
            preferredTaxExemption === "us-citizen"
              ? []
              : domesticPaymentInstruments
          ),
        },
      },
      sequence: ["block.upi"],
      preferences: {
        [`show_default_blocks`]: false,
      },
    },
  };
}
