import React, { useCallback, useEffect, useRef } from "react";
import signVerified from "../assets/sign-verified.svg";
import styles from "./heroSection.module.scss";
import Picture from "./picture";

const HeroSection = props => {
  const bannerSection = props.banner;
  let activeIndex = useRef(0);
  let indicatorClicked = false;
  let carouselInterval = useRef(null);

  const setActiveIndex = useCallback(totalCarouselItemCount => {
    const indicatorContainer = document.getElementsByClassName(
      styles.carouselControl
    );
    const atags = indicatorContainer[0].children;
    if (activeIndex.current + 1 < totalCarouselItemCount) {
      atags[activeIndex.current].classList.remove(styles.active);
      activeIndex.current++;
      atags[activeIndex.current].classList.add(styles.active);
    } else {
      atags[activeIndex.current].classList.remove(styles.active);
      activeIndex.current = 0;
      atags[activeIndex.current].classList.add(styles.active);
    }
  }, []);
  const nextBanner = useCallback(() => {
    const carousel = document.getElementById("banner-carousel");
    if (carousel) {
      const carouselItems = document.getElementsByClassName(
        styles.carouselItem
      );
      if (carouselItems.length > 1) {
        const itemWidth = carouselItems[0].clientWidth;
        let calculatedItemWidth = 0;

        if (itemWidth * carouselItems.length !== carousel.scrollWidth) {
          calculatedItemWidth = carousel.scrollWidth / carouselItems.length;
        }

        const ItemWidth =
          calculatedItemWidth === 0 ? itemWidth : calculatedItemWidth;

        if (
          carousel.scrollLeft < carousel.scrollWidth - ItemWidth &&
          carousel.scrollLeft <
            carousel.scrollWidth - ItemWidth - carousel.scrollWidth * 0.05
        )
          carousel.scrollBy({ left: itemWidth, top: 0, behavior: "smooth" });
        else {
          carousel.scrollTo({ left: 0, top: 0, behavior: "smooth" });
        }
        setActiveIndex(carouselItems.length);
      }
    }
  }, [setActiveIndex]);
  const runHeroCarousel = useCallback(() => {
    carouselInterval.current = setInterval(() => {
      if (indicatorClicked === false) {
        nextBanner();
      }
    }, 12000);
  }, [indicatorClicked, nextBanner]);
  useEffect(() => {
    runHeroCarousel();
    return () => clearInterval(carouselInterval.current);
  }, [runHeroCarousel]);

  const handleClick = (e, element, index) => {
    indicatorClicked = true;
    const banner = document.getElementById(element);
    if (!banner) return;
    if (banner.scrollIntoViewIfNeeded) {
      e.preventDefault();
      banner.scrollIntoViewIfNeeded();
    } else if (banner.scrollIntoView) {
      e.preventDefault();
      banner.scrollIntoView();
    }

    const indicatorContainer = document.getElementsByClassName(
      styles.carouselControl
    );
    const atags = indicatorContainer[0].children;
    atags[activeIndex.current].classList.remove(styles.active);
    activeIndex.current = index;
    atags[activeIndex.current].classList.add(styles.active);
    setTimeout(() => {
      indicatorClicked = false;
    }, 3000);
  };

  return (
    <section
      className={`col-12 text-white ${styles.carouselContainer} px-0 position-relative`}
    >
      <div id="banner-carousel" className={`${styles.carousel} d-flex`}>
        {bannerSection
          .sort((a, b) => (a.sequenceno > b.sequenceno ? 1 : -1))
          .map((x, index) => {
            return (
              <div
                id={`slide-${index + 1}`}
                className={`${styles.carouselItem} w-100`}
                key={index}
              >
                <div className="w-100 h-100">
                  <Picture
                    image={x.image}
                    className={`${styles.carouselImage} abs-full img-default`}
                  />
                  <div
                    className={`${styles.carouselImgOverlay} abs-top-left w-100 h-100`}
                  ></div>
                </div>
                <div
                  className={`${styles.captionContainer} abs-top-left d-flex flex-column justify-content-center align-items-center align-items-lg-start`}
                >
                  <h1
                    className={`${styles.captionTitle} font-weight-bolder m-0 text-center text-lg-left`}
                  >
                    {x.title}
                  </h1>
                  <p
                    className={`${styles.captionSubtitle} text-center text-lg-left`}
                  >
                    {x.description}
                  </p>
                  {props.ngo.logo && (
                    <div>
                      <Picture
                        image={props.ngo.logo}
                        className={`${styles.ngoImage}`}
                      />
                      <div className="d-flex align-items-center mt-3">
                        <img
                          className={styles.verifiedSign}
                          src={signVerified}
                          alt="Verified sign"
                        />
                        <span className="mb-0 text-8BC540 text-18 ml-2">
                          Verified
                        </span>
                      </div>
                    </div>
                  )}
                  {x.buttonLink && (
                    <a href={x.buttonLink}>
                      <button
                        className={`btn button-outline-8BC540 button-bg-translucent-dark ${styles.captionButton} font-16 fw-500 letter-spacing-019 text-uppercase`}
                      >
                        {x.buttontitle}
                      </button>
                    </a>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      {bannerSection.length > 1 && (
        <div
          className={`${styles.carouselControl} w-100 position-absolute text-center`}
        >
          {bannerSection.map((x, index) => {
            return (
              <a
                href={`#slide-${index + 1}`}
                key={index}
                onClick={e => {
                  handleClick(e, `slide-${index + 1}`, index);
                }}
                className={`${
                  index === activeIndex.current ? styles.active : ""
                } d-inline-block`}
              >
                <span
                  className={`${styles.controlDot} d-block cursor-pointer`}
                ></span>
              </a>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default HeroSection;
