import React from "react";
import styles from "./noDonorsYet.module.scss";

function NoDonorsYet({ colorClass, text }) {
  return (
    <div
      className={`d-flex align-items-center text-center font-20 ${styles.donorList} justify-content-center`}
    >
      <p className={`${colorClass} m-0`}>{text}</p>
    </div>
  );
}

export default NoDonorsYet;
