import React, { useContext } from "react";
import { hideModal, showModal } from "../store/actions";
import { StateContext } from "../store/context";
import { DonateNowForm } from "./donateNowForm";
import styles from "./helpModal.module.scss";
export default function HelpModal({ campaign }) {
  const { dispatch, state } = useContext(StateContext);
  const { currency } = state;
  const closeHelpAndOpenDonation = e => {
    showModal(
      dispatch,
      () => <DonateNowForm campaign={campaign} />,
      () => showModal(dispatch, () => <HelpModal campaign={campaign} />)
    );
  };
  return (
    <div className="text-center">
      <img
        className={styles.successTickIcon}
        src="/icons/sad.svg"
        alt="Sad face"
      />
      <h1 className={`${styles.helpPopUpTitle}`}>
        A needy could really use your help right now.
      </h1>
      <p className={`${styles.helpPopUpSubtitle}`}>
        You can make an impact with a contribution as small as{" "}
        {currency === "INR" ? "₹ 1000" : "$ 20"}
      </p>
      <button
        type="button"
        className={`btn button-8BC540 ${styles.helpButton} font-16 fw-500 letter-spacing-019 text-uppercase`}
        onClick={closeHelpAndOpenDonation}
      >
        Help a needy
      </button>
      <button
        className={`${styles.declineHelpText} btn btn-link`}
        type="button"
        onClick={() => {
          hideModal(dispatch);

          document
            .getElementsByTagName("html")[0]
            .classList.remove("overflowYHidden");
        }}
      >
        <span> No, I don’t want to help </span>
      </button>
    </div>
  );
}
