import React, { useContext } from "react";
import { StateContext } from "../store/context";
import styles from "./currencyDropdown.module.scss";
const currencies = [
  { value: "INR", display: "₹" },
  { value: "USD", display: "$" },
  { value: "GBP", display: "£" },
  { value: "SGD", display: "SGD" },
  { value: "HKD", display: "HK$" },
  { value: "AED", display: "AED" },
  { value: "AUD", display: "A$" },
  { value: "CAD", display: "CA$" },
  { value: "EUR", display: "€" },
];
export default function CurrencyDropdown({ onChange, className = "" }) {
  const {
    dispatch,
    state: { currency },
  } = useContext(StateContext);
  const handleChange = e => {
    dispatch({
      type: "CURRENCY_CHANGED",
      payload: e.target.value,
    });
    dispatch({
      type: "AMOUNT_CHANGED",
      payload: e.target.value === "INR" ? 10000 : 250,
    });
  };
  const selectedCurrencyObject = currencies.find(x => x.value === currency);
  return (
    <select
      className={`custom-select custom-select-sm ${styles.currencyDropdown} ml-2 ${className}`}
      style={{ textIndent: `${25 / selectedCurrencyObject.display.length}%` }}
      name="currency"
      onChange={handleChange}
      value={currency}
    >
      {currencies.map(currency => (
        <option value={currency.value} key={currency.value}>
          {currency.display}
        </option>
      ))}
    </select>
  );
}
