import { Link } from "gatsby";
import React, { useContext, useEffect, useRef, useState } from "react";
import { classNames } from "../helpers/classNames";
import { useGetFundRaisedByCampaign } from "../helpers/hooks";
import { showModal } from "../store/actions";
import { StateContext } from "../store/context";
import infoIcon from "../assets/info-icn.svg";
import CurrencyDropdown from "./currencyDropdown";
import styles from "./donateNow.module.scss";
import { DonateNowForm } from "./donateNowForm";
import HelpModal from "./helpModal";
import PaymentOptions from "./paymentOptions";
import ParentCampaignDonorCount from "./parentCampaignDonorCount";
import ChildCampaignDonorCount from "./childCampaignDonorCount";

function DonateNow({ campaign, tagLine, childCampaign }) {
  const slug = campaign.slug;

  const { state, dispatch } = useContext(StateContext);
  const amount = state.amount;
  const donateNowFormTitleRef = useRef();
  const [isFormVisible, setIsFormVisible] = useState(true);

  const { data: { fundRaised } = {} } = useGetFundRaisedByCampaign(slug);
  const isInternationalModeSupported =
    campaign.ngo.intRzpKeyId && campaign.ngo.intRzpKeyEncryptedSecret;
  const handleAmountChange = (e, index) => {
    let customAmount = null;
    if (index === 1) customAmount = document.getElementById("customAmount1");
    else customAmount = document.getElementById("customAmount2");
    if (
      customAmount.value.length > 1 &&
      customAmount.value.search(/^0+(?!$)/g) > -1
    ) {
      customAmount.value = customAmount.value.replace(/^0+(?!$)/g, "");
    }
    dispatch({
      type: "AMOUNT_CHANGED",
      payload: +e.target.value,
    });
  };

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "300px",
      threshold: [0, 1.0],
    };
    const callback = ([entry]) => {
      if (entry.isIntersecting) {
        setIsFormVisible(true);
      } else {
        setIsFormVisible(false);
      }
    };
    let observer = new IntersectionObserver(callback, options);
    observer.observe(donateNowFormTitleRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSubmitClick = e => {
    e.preventDefault();
    showModal(
      dispatch,
      () => (
        <DonateNowForm
          campaign={campaign}
          childCampaign={childCampaign}
          parentFundRaised={fundRaised}
        />
      ),
      () => showModal(dispatch, () => <HelpModal campaign={campaign} />)
    );
  };
  return (
    <>
      <section className={`${styles.donateNowContainer} p-4 shadow-lg`}>
        {!childCampaign && <ParentCampaignDonorCount slug={slug} />}
        {childCampaign && (
          <ChildCampaignDonorCount childCampaign={childCampaign} slug={slug} />
        )}

        <p
          className="text-435061 text-uppercase font-weight-bold font-20 my-4"
          ref={donateNowFormTitleRef}
        >
          {tagLine}
        </p>
        <form className="form row justify-content-center mx-0">
          <fieldset className="col-12 row justify-content-between form-group px-0">
            <legend className="text-798585 font-16">
              <div className="mb-2">Select an amount you want to donate</div>
              <PaymentOptions />
            </legend>
          </fieldset>
          <div className={`row w-100 ${styles.donateNowFormContainer}`}>
            <div className={`form-group mx-0 col-12 mt-4 mb-4 mb-md-4 px-0`}>
              {isInternationalModeSupported && <CurrencyDropdown />}
              <input
                className="form-control mb-0"
                type="number"
                id="customAmount1"
                name="customAmount"
                value={amount}
                maxLength="9"
                min={1}
                onChange={e => {
                  handleAmountChange(e, 1);
                }}
              ></input>
              <label
                htmlFor="customAmount"
                className={`${styles.heroDonateBoxlabel}`}
              >
                Enter Any Other Amount
              </label>
              {!isInternationalModeSupported && (
                <small className="text-637285 font-14 d-flex align-items-center mt-1">
                  <img
                    src={infoIcon}
                    className={`${styles.infoIcon} mr-1`}
                    alt="Info icon"
                  />{" "}
                  This fundraiser can only accept INR donations!
                </small>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="btn d-inline-flex align-items-center justify-content-center col-12 py-3 button-8BC540"
            onClickCapture={handleSubmitClick}
            id="form-donate-now"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-suit-heart-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
            </svg>
            <span
              className={`ml-2 h5 mb-0 font-16 text-uppercase pe-none ${styles.btnTextPadding}`}
            >
              Donate NOW
            </span>
          </button>
        </form>
      </section>
      <div
        className={classNames({
          [`${styles.sticky} shadow`]: true,
          [`slide-down ${styles.active}`]: !isFormVisible,
        })}
      >
        <div className="container">
          <div className="row align-items-center h-100">
            <span className="col-3 col-lg-4 h5">
              <Link to="/" title="Back" alt="back" className="text-black mr-3">
                <svg
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="15"
                  strokeWidth="2"
                >
                  <path
                    d="M1.5 7.5l4-4m-4 4l4 4m-4-4H14"
                    stroke="currentColor"
                  ></path>
                </svg>
              </Link>
              <span>Select an amount you want to donate</span>
            </span>
            <div className="col-9 col-lg-8">
              <div className="row align-items-center">
                <div className="col-6">
                  <PaymentOptions />
                </div>
                <div className="col-3 position-relative">
                  {isInternationalModeSupported && <CurrencyDropdown />}
                  <input
                    className="col-12 form-control"
                    type="number"
                    id="customAmount2"
                    name="customAmount"
                    value={amount}
                    maxLength="9"
                    min={1}
                    onChange={e => {
                      handleAmountChange(e, 2);
                    }}
                    placeholder="Enter Other Amount"
                  />
                </div>
                <button
                  type="button"
                  className="btn button-8BC540 font-16 font-weight-bold text-uppercase col-3"
                  onClickCapture={handleSubmitClick}
                  id="sticky-donate-now"
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-suit-heart-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                  </svg>
                  <span
                    className={`ml-2 h5 mb-0  pe-none font-16 text-uppercase py-4`}
                  >
                    Donate NOW
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DonateNow;
