import React from "react";
import Picture from "./picture";
import styles from "./testimonials.module.scss";

const TestimonialsSection = props => {
  const testimonialsLength = props.testimonials.length;

  const clickedPrevious = () => {
    const carousel = document.getElementById("testimonial-carousel");
    const carouselItems = document.getElementsByClassName(
      styles.userTestimonial
    );
    if (carouselItems) {
      const itemWidth = carouselItems[0].clientWidth;

      if (carousel.scrollLeft !== 0)
        carousel.scrollBy({ left: -itemWidth, top: 0, behavior: "smooth" });
      else
        carousel.scrollTo({
          left: carousel.scrollWidth,
          top: 0,
          behavior: "smooth",
        });
    }
  };

  const clickedNext = () => {
    const carousel = document.getElementById("testimonial-carousel");
    const carouselItems = document.getElementsByClassName(
      styles.userTestimonial
    );
    if (carouselItems) {
      const itemWidth = carouselItems[0].clientWidth;
      let calculatedItemWidth = 0;
      let mulitplier = 0;

      if (itemWidth * carouselItems.length !== carousel.scrollWidth) {
        calculatedItemWidth = carousel.scrollWidth / carouselItems.length;
      }

      const ItemWidth =
        calculatedItemWidth === 0 ? itemWidth : calculatedItemWidth;

      const w = window.innerWidth;
      if (w > 991) {
        mulitplier = 0.4;
      } else {
        mulitplier = 0.15;
      }

      if (
        carousel.scrollLeft < carousel.scrollWidth - ItemWidth &&
        carousel.scrollLeft <
          carousel.scrollWidth - ItemWidth - carousel.scrollWidth * mulitplier
      )
        carousel.scrollBy({ left: itemWidth, top: 0, behavior: "smooth" });
      else {
        carousel.scrollTo({ left: 0, top: 0, behavior: "smooth" });
      }
    }
  };

  function returnStartrating(starRating) {
    let stars = null;
    for (let i = 0; i < starRating; i++) {
      if (i === 0) {
        stars = "&#9733;";
      } else {
        stars = stars + "&#9733;";
      }
    }
    return stars;
  }

  return (
    <section className={`col-12 ${styles.testimonialsSection}`}>
      <span className="pill-label pill-bg-white">testimonials</span>
      <h3 className="section-title text-white text-capitalize">
        What People Are Saying
      </h3>
      <div id="testimonials" className="position-relative row">
        <div
          id="testimonial-carousel"
          className={`${styles.carousel} d-flex align-items-top \
            ${
              testimonialsLength === 2
                ? "justify-content-start justify-content-md-center "
                : testimonialsLength < 2
                ? "justify-content-center"
                : "justify-content-start"
            }\
            align-content-stretch w-100`}
        >
          {props.testimonials
            .sort((a, b) => (a.sequenceno > b.sequenceno ? 1 : -1))
            .map((x, index) => {
              return (
                <div
                  id={`testimonial-${index + 1}`}
                  className={`${styles.userTestimonial}`}
                  key={x.testimonialBy}
                >
                  <div
                    className={`${styles.testimonialImageContainer} position-relative`}
                  >
                    {x.image && x.image.fluid && (
                      <Picture
                        image={x.image}
                        className={`${styles.testimonialImage} abs-full`}
                        height={380}
                      />
                    )}
                  </div>
                  <div className={`text-white mt-4 text-center w-100`}>
                    <p
                      className={`mb-0 font-30  ${styles.pcolor}`}
                      dangerouslySetInnerHTML={{
                        __html: returnStartrating(x.starrating),
                      }}
                    ></p>
                    <p className="mb-0 font-24 font-weight-bold">
                      {x.testimonialBy}
                    </p>
                    <p className="text-54EDFF font-16">{x.designation}</p>
                  </div>
                  <p className="font-16 text-DAF0FC text-center h-100 d-flex flex-column justify-content-center">
                    <q>{x.description}</q>
                  </p>
                </div>
              );
            })}
        </div>
        {props.testimonials.length > 1 && (
          <div className={`display-flex text-center mt-5 w-100`}>
            <img
              className={`${styles.prev} mr-4`}
              src="/icons/left.svg"
              alt="Previous testimonial"
              onClick={clickedPrevious}
            />
            <img
              className={`${styles.next}`}
              src="/icons/right.svg"
              alt="Next testimonial"
              onClick={clickedNext}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default TestimonialsSection;
