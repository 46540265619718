import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";
import styles from "./fundUsageModal.module.scss";
export default function FundUsageModal({ fundUsage }) {
  return (
    <div style={{ backgroundImage: `url(/images/help-modal-background.png)` }}>
      <h1
        className={`font-32 mt-1 text-center text-decoration-underline ${styles.fundUsageTitle}`}
      >
        {fundUsage.title}
      </h1>
      <div
        className={` mt-4 pt-2 font-16 text-left ${styles.fundUsageDetails}`}
      >
        {documentToReactComponents(JSON.parse(fundUsage.contents.raw))}
      </div>
    </div>
  );
}
