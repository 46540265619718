import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import { hideModal } from "../store/actions";
import { StateContext } from "../store/context";
import styles from "./paymentSuccessModal.module.scss";
export default function PaymentSuccessModal() {
  const {
    contentfulThankYouSection: { title, subtitle, description },
  } = useStaticQuery(query);
  const { dispatch } = useContext(StateContext);
  return (
    <div
      className="text-center"
      style={{ backgroundImage: `url(/images/Clip.png)` }}
    >
      <img
        className={styles.successTickIcon}
        src="/icons/check.svg"
        alt="Success"
      />
      <div
        className={`${styles.thankYouNoteContainer}`}
        id="donation-received-message-modal"
      >
        <h1 className="mb-0 font-36 font-weight-bold">{title}</h1>
        <p className="mb-0 font-20">{subtitle}</p>
      </div>
      <p className={styles.successSubmessage}>{description.description}</p>
      <button
        className={`btn button-8BC540 ${styles.successClosebUtton} font-16 fw-500 letter-spacing-019 text-uppercase`}
        onClick={() => {
          hideModal(dispatch);
          document
            .getElementsByTagName("html")[0]
            .classList.remove("overflowYHidden");
        }}
      >
        Continue
      </button>
      <p className={`${styles.successShareMessage}`}>
        Share the news with your friends and family
      </p>
      <div className={styles.shareButtonContainer}>
        <button
          className={`btn button-8BC540 btn-bg-fb ${styles.socialShareButton} font-16 fw-500 letter-spacing-019 text-uppercase mb-2`}
        >
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_PUBLIC_URL}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/facebook.svg"
              alt="Facebook"
              className={styles.sharebuttonIcons}
            />
            Share
          </a>
        </button>
        <button
          className={`btn button-8BC540 btn-bg-twitter ${styles.socialShareButton} font-16 fw-500 letter-spacing-019 text-uppercase mb-2`}
        >
          <a
            href={`https://twitter.com/intent/tweet?url==${process.env.GATSBY_PUBLIC_URL}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/twitter.svg"
              alt="Twitter"
              className={styles.sharebuttonIcons}
            />
            Tweet
          </a>
        </button>
        <button
          className={`btn button-8BC540 btn-bg-pinterest ${styles.socialShareButton} font-16 fw-500 letter-spacing-019 text-uppercase mb-2`}
        >
          <a
            href={`https://pinterest.com/pin/create/button/?url=${process.env.GATSBY_PUBLIC_URL}&media=&description=`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/pinterest-circular-logo-symbol.svg"
              alt="Pinterest"
              className={styles.sharebuttonIcons}
            />
            Pin
          </a>
        </button>
        <button
          className={`btn button-8BC540 btn-bg-email ${styles.socialShareButton} font-16 fw-500 letter-spacing-019 text-uppercase`}
        >
          <a
            href={`mailto:info@example.com?&subject=&cc=&bcc=&body=${process.env.GATSBY_PUBLIC_URL}%0A`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/envelope.svg"
              alt="Email"
              className={styles.sharebuttonIcons}
            />
            Email
          </a>
        </button>
        <button
          className={`btn button-8BC540 btn-bg-linkedin ${styles.socialShareButton} font-16 fw-500 letter-spacing-019 text-uppercase`}
        >
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.GATSBY_PUBLIC_URL}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/linkedin.svg"
              alt="LinkedIn"
              className={styles.sharebuttonIcons}
            />
            LinkedIn
          </a>
        </button>
        <button
          className={`btn button-8BC540 btn-bg-whatsapp ${styles.socialShareButton} font-16 fw-500 letter-spacing-019 text-uppercase`}
        >
          <a
            href={`https://wa.me/?text=${process.env.GATSBY_PUBLIC_URL}`}
            data-action="share/whatsapp/share"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/whatsapp.svg"
              alt="Whatsapp"
              className={styles.sharebuttonIcons}
            />
            Share
          </a>
        </button>
      </div>
    </div>
  );
}

const query = graphql`
  query {
    contentfulThankYouSection {
      title
      description {
        description
      }
      subtitle
    }
  }
`;
