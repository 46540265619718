export default function nameInitials(name) {
  if (name) {
    if (name.includes(" ")) {
      const fullName = name.split(" ");
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    } else {
      const initials = name.substring(0, 1);
      return initials.toUpperCase();
    }
  }
}
